import React from 'react';
import SVG from 'react-inlinesvg';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { config, PluginPage } from '@grafana/runtime';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { ContentCard } from '@/components/ContentCard';
import { QuickLink } from '@/components/QuickLink';
import { testIds } from '@/components/testIds';
import { externalLinks, Icons, paths, phrases } from '@/constants';
import { useBillingDashboardPanel, useBillingDashboardUrl } from '@/hooks/dashboards';
import { getCommonStyles } from '@/pages/common-styles';

const getStyles = (theme: GrafanaTheme2) => {
  return {
    ...getCommonStyles(theme),
    optimizeBody: css({
      display: 'flex',
      flexDirection: 'column',
      gap: theme.spacing(2),
    }),
  };
};
export const Logs = () => {
  const styles = useStyles2(getStyles);
  const billingDashboardUrl = useBillingDashboardUrl();
  const billableLogsPanel = useBillingDashboardPanel('billableLogsUsage');
  const queryUsagePanel = useBillingDashboardPanel('queryUsageRatio');
  const lvePluginEnabled = config.apps
    ? Object.keys(config.apps).some((app) => app === 'grafana-logvolumeexplorer-app')
    : false;
  const adaptiveLogsPluginEnabled = config.apps
    ? Object.keys(config.apps).some((app) => app === 'grafana-adaptivelogs-app')
    : false;
  return (
    <PluginPage
      renderTitle={() => <h1>Logs cost management</h1>}
      subTitle={'Analyze, monitor, and optimize your spend on Grafana Cloud Logs'}
    >
      <div className={styles.container}>
        <ContentCard.Card>
          <ContentCard.Header>Inspect</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.inspect}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.inspect} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <QuickLink.Container className={styles.quickLinkGrid}>
              <QuickLink.Link href={billableLogsPanel?.panelUrl || billingDashboardUrl}>
                Billing dashboard - {billableLogsPanel?.panelTitleDisplay}
              </QuickLink.Link>

              <QuickLink.Link href={queryUsagePanel?.panelUrl || billingDashboardUrl}>
                Billing dashboard - {queryUsagePanel?.panelTitleDisplay}
              </QuickLink.Link>
            </QuickLink.Container>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Attribute</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.attribute}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.attribution} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <span className={styles.appPluginLinkContainer}>
              <LinkButton
                data-testid={testIds.logs.pluginLink}
                disabled={!lvePluginEnabled}
                href={paths.logVolumeExplorer}
                variant={'primary'}
              >
                Log Volume Explorer
              </LinkButton>
              <span className={styles.secondaryText}>
                {lvePluginEnabled
                  ? 'Understand where your log data is originating from within your organization using labels'
                  : 'The Log Volume Explorer plugin is not installed.'}
              </span>
            </span>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Optimize</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.optimize.logs}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.optimize} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body className={styles.optimizeBody}>
            <span className={styles.appPluginLinkContainer}>
              <LinkButton
                data-testid={testIds.adaptiveLogs.pluginLink}
                disabled={!adaptiveLogsPluginEnabled}
                href={paths.adaptiveLogs}
                variant={'primary'}
              >
                Adaptive Logs
              </LinkButton>
              <span className={styles.secondaryText}>
                {adaptiveLogsPluginEnabled
                  ? 'Manage your logging costs with Adaptive Logs'
                  : 'The Adaptive Logs plugin is not installed.'}
              </span>
            </span>
            <QuickLink.ExternalLink href={externalLinks.dropLogLinesAgent}>
              How to use Grafana Alloy to drop log lines
            </QuickLink.ExternalLink>
          </ContentCard.Body>
        </ContentCard.Card>
        <ContentCard.Card>
          <ContentCard.Header>Monitor</ContentCard.Header>
          <ContentCard.SubHeader>{phrases.monitoring}</ContentCard.SubHeader>
          <ContentCard.Figure>
            <SVG src={Icons.monitor} width={undefined} height={undefined} />
          </ContentCard.Figure>
          <ContentCard.Body>
            <QuickLink.ExternalLink href={externalLinks.setupUsageAlerts}>
              Set up usage alerts for logs
            </QuickLink.ExternalLink>
          </ContentCard.Body>
        </ContentCard.Card>
      </div>
    </PluginPage>
  );
};
