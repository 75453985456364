import { find as _find, has as _has, lowerCase as _lowerCase } from 'lodash';

import { PanelModel } from '@grafana/data';

import { WithPanels } from '@/types';

/**
 * Recursive function that drills down through objects that have a panels property to find a panel with a given title
 * @param panelHolder
 * @param panelTitle
 */
export const findPanelByTitle = (panelHolder: WithPanels | unknown, panelTitle: string): PanelModel | undefined => {
  if (_has(panelHolder, 'panels') && (panelHolder as WithPanels).panels.length > 0) {
    const panel = _find((panelHolder as WithPanels).panels, (p) => _lowerCase(p.title) === _lowerCase(panelTitle));
    if (panel) {
      return panel;
    } else {
      for (const panelGroup of (panelHolder as WithPanels).panels) {
        const foundPanel = findPanelByTitle(panelGroup, panelTitle);
        if (foundPanel) {
          return foundPanel;
        }
      }
    }
  }
  return;
};
