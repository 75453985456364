import React from 'react';
import SVG from 'react-inlinesvg';
import { css } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

import { Icons, phrases } from '@/constants';
import { PanelLogoIcon } from '@/types';

type CallOutSize = 'small' | 'medium';

const getIconCalloutStyles = (size: CallOutSize) => (theme: GrafanaTheme2) => {
  const iconSize = size === 'small' ? theme.spacing(3) : theme.spacing(4);
  const fontSize = size === 'small' ? theme.typography.fontSize : theme.typography.h4.fontSize;
  return {
    container: css({
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      gap: theme.spacing(1),
      fontSize,
    }),
    icon: css({
      maxHeight: iconSize,
      width: 'fit-content',
      path: {
        fill: theme.colors.text.primary,
      },
    }),
    title: css({
      fontWeight: theme.typography.fontWeightBold,
    }),
    message: css({
      fontStyle: 'italic',
    }),
  };
};

type IconCalloutProps = {
  icon: PanelLogoIcon;
  message: string;
  title: string;
  size: CallOutSize;
};
const IconCallOut = ({ icon, message, title, size }: IconCalloutProps) => {
  const styles = useStyles2(getIconCalloutStyles(size));
  return (
    <div className={styles.container}>
      <SVG className={styles.icon} src={Icons[icon]} width={undefined} height={undefined} />
      <span className={styles.title}>{title}:</span>
      <span className={styles.message}>{message}</span>
    </div>
  );
};

type Props = {
  size?: CallOutSize;
};

export const InspectCallOut = ({ size = 'small' }: Props) => (
  <IconCallOut icon={'inspect'} message={phrases.inspect} title={'Inspect'} size={size} />
);
export const AttributeCallOut = ({ size = 'small' }: Props) => (
  <IconCallOut icon={'attribution'} message={phrases.attribute} title={'Attribute'} size={size} />
);
export const OptimizeCallOut = ({ size = 'small' }: Props) => (
  <IconCallOut icon={'optimize'} message={phrases.optimize.general} title={'Optimize'} size={size} />
);
export const MonitorCallOut = ({ size = 'small' }: Props) => (
  <IconCallOut icon={'monitor'} message={phrases.monitoring} title={'Monitor'} size={size} />
);
