import { useQuery } from '@tanstack/react-query';
import { capitalize as _capitalize, find as _find } from 'lodash';

import { getBackendSrv } from '@grafana/runtime';

import { panels } from '@/constants';
import { DashboardQueryResponse, DashboardResponse, PanelKeys } from '@/types';
import { findPanelByTitle } from '@/utils/methods';

const QueryKeys = {
  dashboardJson: 'dashboardJson',
  dashboardSearch: 'dashboardSearch',
};

export const useDashboardJson = (dashboardUid?: string) => {
  return useQuery([QueryKeys.dashboardJson, dashboardUid], async () => {
    if (!dashboardUid) {
      return { data: undefined };
    }
    const res: DashboardResponse = await getBackendSrv().get(`/api/dashboards/uid/${dashboardUid}`);

    return {
      data: res.dashboard,
    };
  });
};

export const useDashboardSearch = (queryString: string) => {
  return useQuery([QueryKeys.dashboardSearch, queryString], async () => {
    const res: DashboardQueryResponse[] = await getBackendSrv().get(`/api/search?query=${queryString}`);

    return {
      data: res,
    };
  });
};

export const useBillingUsagesDashboard = (): {
  dashboard: DashboardQueryResponse | undefined;
  tier: 'free' | 'paid' | undefined;
} => {
  const { data } = useDashboardSearch('Billing/Usage');
  if (data?.data) {
    // Try to find the paid tier dashboard first
    let dashboard = _find(data.data, (d) => d.title === 'Billing/Usage');

    let tier: 'free' | 'paid' = 'paid';

    if (!dashboard) {
      // Free tier dashboard
      dashboard = _find(data.data, (d) => d.title === 'Grafana Cloud Billing/Usage');
      tier = 'free';
    }
    return { dashboard, tier };
  }
  return { dashboard: undefined, tier: undefined };
};

export const useBillingDashboardUrl = () => {
  const { dashboard } = useBillingUsagesDashboard();
  return dashboard?.url;
};

export const useBillingDashboardPanel = (key: PanelKeys) => {
  const { dashboard, tier } = useBillingUsagesDashboard();
  const { data } = useDashboardJson(dashboard?.uid);

  if (dashboard && tier && data?.data) {
    const panelTitle = panels[tier][key];
    let panel = findPanelByTitle(data.data, panelTitle);

    if (panel) {
      return { panelUrl: `${dashboard.url}?viewPanel=${panel.id}`, panelTitleDisplay: _capitalize(panelTitle) };
    } else {
      console.warn(`Panel with title '${panelTitle}' not found in dashboard ${dashboard.title}`);
    }
  }
  // Return default panel title with no url if panel not found
  return { panelTitleDisplay: _capitalize(panels.paid[key]) };
};
