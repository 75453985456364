import React from 'react';
import { css, cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { useStyles2 } from '@grafana/ui';

const getStyles = (theme: GrafanaTheme2) => ({
  card: css({
    backgroundColor: theme.colors.background.secondary,
    padding: theme.spacing(2),
  }),
  grid: {
    noFigure: css({
      display: 'grid',
      gridTemplateColumns: 'auto',
      gap: theme.spacing(2),
    }),
    withFigure: css({
      display: 'grid',
      gridTemplateColumns: `${theme.spacing(4.5)} auto`,
      gap: theme.spacing(2),
    }),
  },
  subheader: css({
    color: theme.colors.text.secondary,
    fontStyle: 'italic',
  }),
  contentColumn: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
});

const Header = ({ children, level = 3 }: { children: React.ReactNode; level?: 1 | 2 | 3 }) => {
  if (level === 1) {
    return <h1>{children}</h1>;
  } else if (level === 2) {
    return <h2>{children}</h2>;
  }
  return <h3>{children}</h3>;
};

const SubHeader = ({ children }: { children: React.ReactNode }) => {
  const styles = useStyles2(getStyles);
  return <span className={styles.subheader}>{children}</span>;
};

const Figure = ({ children }: { children: React.ReactNode }) => {
  return <span>{children}</span>;
};

/**
 * Body is just a pass through. Gives the implementer something to put their content as opposed to having it adjacent to the other components
 * @param children
 * @param className
 * @constructor
 */
const Body = ({ children, className }: { children: React.ReactNode; className?: string }) => {
  return <div className={className}>{children}</div>;
};

type Props = {
  children: React.ReactNode;
};
const Card = ({ children }: Props) => {
  const styles = useStyles2(getStyles);
  const nodes: {
    header?: React.ReactNode;
    subheader?: React.ReactNode;
    figure?: React.ReactNode;
    body: React.ReactNode[];
  } = { body: [] };

  React.Children.forEach(children, (child) => {
    if (React.isValidElement(child)) {
      if (child.type === Figure) {
        nodes.figure = child;
      } else if (child.type === Header) {
        nodes.header = child;
      } else if (child.type === SubHeader) {
        nodes.subheader = child;
      } else {
        nodes.body.push(child);
      }
    }
  });

  return (
    <div className={cx(styles.card, nodes.figure ? styles.grid.withFigure : styles.grid.noFigure)}>
      {nodes.figure && <div>{nodes.figure}</div>}
      <div className={styles.contentColumn}>
        <span>
          {nodes.header && nodes.header}
          {nodes.subheader && nodes.subheader}
        </span>
        {nodes.body}
      </div>
    </div>
  );
};

export const ContentCard = {
  Body,
  Card,
  Figure,
  Header,
  SubHeader,
};
