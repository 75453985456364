import React, { Suspense } from 'react';
import { Route, Switch } from 'react-router-dom';
import { QueryClientProvider } from '@tanstack/react-query';

import { AppRootProps } from '@grafana/data';

import { testIds } from '@/components/testIds';
import { ROUTES } from '@/constants';
import { Overview } from '@/pages';
import { Logs } from '@/pages/Logs';
import { Metrics } from '@/pages/Metrics';
import { prefixRoute } from '@/utils/routing';
import { queryClient } from '@/utils/state';

export function App(props: AppRootProps) {
  return (
    <QueryClientProvider client={queryClient}>
      <Suspense fallback={<div data-testid={testIds.suspense.loading}>Loading...</div>}>
        <Switch>
          <Route exact path={prefixRoute(ROUTES.Logs)} component={Logs} />
          <Route exact path={prefixRoute(ROUTES.Metrics)} component={Metrics} />

          {/* Default page */}
          <Route component={Overview} />
        </Switch>
      </Suspense>
    </QueryClientProvider>
  );
}
