import React from 'react';
import { css, cx } from '@emotion/css';

import { GrafanaTheme2 } from '@grafana/data';
import { PluginPage } from '@grafana/runtime';
import { LinkButton, useStyles2 } from '@grafana/ui';

import { ContentBox } from '@/components/ContentBox';
import { AttributeCallOut, InspectCallOut, MonitorCallOut, OptimizeCallOut } from '@/components/IconCallout';
import { QuickLink } from '@/components/QuickLink';
import { externalLinks, paths, ROUTES } from '@/constants';
import { useBillingDashboardUrl } from '@/hooks/dashboards';

const getStyles = (theme: GrafanaTheme2) => ({
  container: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr',
    gridTemplateRows: `repeat(6, auto)`,
    gap: theme.spacing(2),
  }),
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
  list: css({
    marginLeft: theme.spacing(3),
  }),
  twoSplitBox: css({
    display: 'grid',
    gridTemplateColumns: '1fr 1fr',
    gap: theme.spacing(10),
  }),
  callOutBox: css({
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    fontSize: theme.typography.h3.fontSize,
  }),
  exploreCostManagementContainer: css({
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    gap: theme.spacing(2),
    alignItems: 'center',
    a: {
      width: 'max-content',
    },
  }),
  vidPlaceholder: css({
    width: '100%',
  }),
  iconCallouts: css({
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(2),
  }),
  iframeContainer: css({
    display: 'flex',
    height: '100%',
    width: '100%',
    iframe: {
      height: '100%',
      width: '100%',
    },
  }),
  inColumn: (column: number, span?: number) => {
    if (span) {
      return css({
        gridColumn: `${column} / span ${span}`,
      });
    } else {
      return css({
        gridColumn: column,
      });
    }
  },
  inRow: (row: number, span?: number) => {
    if (span) {
      return css({
        gridRow: `${row} / span ${span}`,
      });
    } else {
      return css({
        gridRow: row,
      });
    }
  },
});

export const Overview = () => {
  const styles = useStyles2(getStyles);
  const billingDashboardUrl = useBillingDashboardUrl();

  return (
    <PluginPage>
      <div className={styles.container}>
        <ContentBox className={cx(styles.twoSplitBox, styles.inColumn(1, 3), styles.inRow(1, 2))}>
          <div>
            <h2>What is Cost management?</h2>A centralized hub with a suite of cost management tools for Grafana Cloud
            administrators to manage, control, and optimize observability spend. Use the different tools within the cost
            management hub to answer cost-related questions.
          </div>
          <div className={styles.iconCallouts}>
            <InspectCallOut />
            <AttributeCallOut />
            <OptimizeCallOut />
            <MonitorCallOut />
          </div>
        </ContentBox>
        <ContentBox className={cx(styles.callOutBox, styles.inColumn(1), styles.inRow(3))}>
          Manage your Grafana Cloud Metrics cost
          <LinkButton variant={'primary'} href={paths.appRoot + `/${ROUTES.Metrics}`}>
            Metrics cost management
          </LinkButton>
        </ContentBox>
        <ContentBox className={cx(styles.callOutBox, styles.inColumn(1), styles.inRow(4))}>
          Manage your Grafana Cloud Logs cost
          <LinkButton variant={'primary'} href={paths.appRoot + `/${ROUTES.Logs}`}>
            Logs cost management
          </LinkButton>
        </ContentBox>
        <ContentBox className={cx(styles.callOutBox, styles.inColumn(1), styles.inRow(5))}>
          Overview of usage and balance for the current billing period
          <QuickLink.Link href={billingDashboardUrl}>Billing dashboard</QuickLink.Link>
        </ContentBox>
        <ContentBox className={cx(styles.callOutBox, styles.inColumn(1), styles.inRow(6))}>
          To view invoices, use the{' '}
          <QuickLink.ExternalLink href={externalLinks.grafanaInvoices}>Cloud Portal</QuickLink.ExternalLink>
        </ContentBox>
        <ContentBox className={cx(styles.inColumn(2, 2), styles.inRow(3, 4))}>
          <div className={styles.iframeContainer}>
            <iframe
              title="Cost management - introductory video"
              src="https://player.vimeo.com/video/875751933?transparent=0"
              allow="autoplay; fullscreen"
              allowFullScreen
              // This is necessary because color-scheme defined on :root has impact on iframe elements
              // More about how color-scheme works for iframes https://github.com/w3c/csswg-drafts/issues/4772
              // Summary: If the color scheme of an iframe differs from embedding document iframe gets an opaque canvas bg appropriate to its color scheme
              style={{ border: 'none', colorScheme: 'light dark' }}
            ></iframe>
          </div>
        </ContentBox>
      </div>
    </PluginPage>
  );
};
